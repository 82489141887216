import { Formik, Form, Field } from 'formik';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Card, FormGroup, Label, Button } from 'reactstrap';
import { Project } from '@interfaces/consumer/projects';
import { UpdateProjectModel } from '@models/consumer/projects';
import * as Yup from 'yup';
import { usePromisedDispatch } from '../../../../utils/helpers';
import { projectActions } from '@store/consumer/projectsStore';
import { FormikRowCheckbox, FormikRowInput } from '@components/panel/formik';
import { FC } from 'react';

interface ProjectSettingsTabProps {
    project: Project;
}

interface ProjectSettingsTabForm {
    name: string;
    title: string;
    url: string;
    description: string;
    rePublic: string;
    reSecret: string;
    isActive: boolean;
    defaultLanguage: string;
    googleTagId: string;
    ymId: number;
    mailRuCounterId: string;
    donateMin: number;
    donateMax: number;
}

const ProjectSettingsTab: FC<ProjectSettingsTabProps> = ({ project }) => {

    const dispatch = usePromisedDispatch();

    const onSubmit = async (values: ProjectSettingsTabForm) => {

        const pr: UpdateProjectModel = {
            id: project.id,
            description: values.description,
            name: values.name,
            title: values.title,
            isActive: values.isActive,
            recaptchaPublic: values.rePublic,
            recaptchaSecret: values.reSecret,
            defaultLanguage: values.defaultLanguage,
            googleTagId: values.googleTagId,
            ymId: values.ymId,
            mailRuCounterId: values.mailRuCounterId,
            donateMin: values.donateMin,
            donateMax: values.donateMax
        }

        const result = await dispatch(projectActions.updateProject(pr));

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success("Настройки проекта", "Настройки сохранены", options);
        }
        else {
            toastr.error("Настройки проекта", "Ошибка запроса. Обновите страницу", options);
        }
    }

    const validate = Yup.object().shape({
        name: Yup.string()
            .max(64)
            .required(),
        description: Yup.string()
            .max(256)
            .notRequired(),
        defaultLanguage: Yup.string()
            .required(),
        donateMin: Yup.number()
            .min(0)
            .max(1000000)
            .required(),
        donateMax: Yup.number()
            .min(0)
            .max(1000000)
            .required(),
    });

    const initial: ProjectSettingsTabForm = {
        name: project.name,
        description: project.description || '',
        url: project.url || '', //хз почему-то ругается на null value
        rePublic: project.recaptchaPublic || '',
        reSecret: project.recaptchaSecret || '',
        isActive: project.isActive,
        defaultLanguage: project.defaultLanguage || '',
        googleTagId: project.googleTagId || '',
        title: project.title || '',
        ymId: project.ymId || 0,
        donateMin: project.donateMin || 0,
        donateMax: project.donateMax || 0,
        mailRuCounterId: project.mailRuCounterId || ''
    }

    return (
        <Card body>
            <Formik
                initialValues={initial}
                validationSchema={validate}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <FormGroup row>
                            <Label sm="3">ID проекта</Label>
                            <Label sm="9">{project.id}</Label>
                        </FormGroup>
                        <Field
                            label="Название проекта"
                            type="text"
                            name="name"
                            placeholder="your-project.com"
                            component={FormikRowInput} />
                        <Field
                            label="Адрес проекта"
                            type="text"
                            name="url"
                            placeholder="https://your-project.com"
                            disabled
                            component={FormikRowInput} />
                        <Field
                            label="Титул кабинета"
                            type="text"
                            name="title"
                            placeholder="Титул страницы"
                            component={FormikRowInput} />
                        <Field
                            label="Описание проекта"
                            type="text"
                            name="description"
                            placeholder="Что душе угодно"
                            component={FormikRowInput} />
                        <Field
                            label="Активен"
                            name="isActive"
                            type="switch"
                            description="Вы можете отключить проект"
                            component={FormikRowCheckbox} />
                        <Field
                            label="ReCAPTCHA публичный ключ"
                            type="text"
                            name="rePublic"
                            placeholder="6Le_wdk..."
                            description="ReCAPTCHA обязательна для работы личного кабинета"
                            component={FormikRowInput} />
                        <Field
                            label="ReCAPTCHA секретный ключ"
                            type="text"
                            name="reSecret"
                            placeholder="6Le_wdk..."
                            component={FormikRowInput} />
                        <Field
                            label="Google Tag Id"
                            type="text"
                            name="googleTagId"
                            placeholder="GTM-0000000"
                            component={FormikRowInput} />
                        <Field
                            label="Yandex metrika"
                            type="number"
                            name="ymId"
                            placeholder="1234"
                            component={FormikRowInput} />
                        <Field
                            label="Mail.ru counter"
                            type="text"
                            name="mailRuCounterId"
                            placeholder="1234"
                            component={FormikRowInput} />
                        <Field
                            label="Минимальный донат"
                            type="number"
                            name="donateMin"
                            placeholder="50"
                            component={FormikRowInput} />
                        <Field
                            label="Максимальный донат"
                            type="number"
                            name="donateMax"
                            placeholder="15000"
                            component={FormikRowInput} />
                        <Field
                            label="Язык по умолчанию"
                            type="text"
                            name="defaultLanguage"
                            placeholder="ru"
                            component={FormikRowInput} />
                        <div className="text-center mt-3">
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default ProjectSettingsTab;