import { FC, useCallback, useEffect, useState, memo } from 'react';
import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, useHistory} from 'react-router-dom';
import { Card, CardHeader, CardTitle, Table } from 'reactstrap';
import { ILanguageShort } from '@interfaces/consumer/themes';
import { AnimatedFontAwesomeIcon, TableSpinner } from '../../../panel';
import ThemesService from '@api/consumer/themesService';
import { useCurrentProjectId } from '@selectors/consumer/projects.selectors';

const LanguagesListTable: FC = () => {
    
    const projectId = useCurrentProjectId();

    //const [isModalOpen, setModalOpen] = useState(false);
    const [languages, setLanguages] = useState<ILanguageShort[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    
    
    useEffect(() => {
        const loadLanguages = async () => {
            setIsLoading(true);
            try {
                const themesService = new ThemesService();
                const data = await themesService.getLanguages(projectId);
                if(data.success) {
                    setLanguages(data.data);
                }
            } finally {
                setIsLoading(false);
            }
        };
        
        loadLanguages();
    }, [projectId]);

    //const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);

    return (
        <Card>
            <TableList>
                {isLoading ? (
                    <TableSpinner colSpan={3} />
                ) : languages.length > 0 ? (
                    languages.map((language) => (
                        <TableItem 
                            language={language} 
                            key={language.id}
                            onDelete={() => {
                                setLanguages(prev => prev.filter(l => l.id !== language.id));
                            }} 
                        />
                    ))
                ) : (
                    <tr key="nolanguages">
                        <td align="center" colSpan={3}>Языков еще нет</td>
                    </tr>
                )}
            </TableList>
        </Card>
    );
};

const TableList: FC = memo(({ children }) => {
    const history = useHistory();
    const projectId = useCurrentProjectId();

    const toggleModal = () => history.push(`/p/${projectId}/v/l/new`);

    return (
        <>
            <CardHeader>
                <div className="card-actions float-end">
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        fixedWidth
                        className="align-middle"
                        onClick={toggleModal}
                        animate="spin"
                    />
                </div>
                <CardTitle tag="h5">
                    Список языков
                </CardTitle>
            </CardHeader>
            <Table className="table table-hover">
                <thead>
                <tr>
                    <th style={{ width: "45%" }}>Наименование</th>
                    <th style={{ width: "45%" }}>Код</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {children}
                </tbody>
            </Table>
        </>
    )
});

interface ItemProps {
    language: ILanguageShort;
    onDelete: () => void;
}

const TableItem: FC<ItemProps> = memo(({ language, onDelete }) => {

    const handleDelete = useCallback(async () => {
        if (window.confirm('Вы действительно хотите удалить этот язык?')) {
            const themesService = new ThemesService();
            await themesService.deleteLanguage(language.projectId, language.id);
            onDelete();
        }
    }, [language, onDelete]);

    return (
        <tr key={language.id}>
            <td>{language.name}</td>
            <td>{language.code}</td>
            <td>
                <Link to={`/p/${language.projectId}/v/l/${language.id}/`} className="me-2">
                    <FontAwesomeIcon
                        icon={faPen}
                        fixedWidth
                        className="align-middle"
                    />
                </Link>
                <FontAwesomeIcon
                    icon={faTrash}
                    fixedWidth
                    className="align-middle"
                    onClick={handleDelete}
                    style={{ cursor: 'pointer' }}
                />
            </td>
        </tr>
    );
});

export default LanguagesListTable;

