import {
    faBoxes, faBucket, faCode,
    faCog, faCreditCard,
    faDollarSign,
    faDoorOpen, faEnvelope,
    faGamepad, faCubes,
    faNetworkWired, faServer, faSliders, faUsersLine, faWallet, faWrench, faLanguage, faPalette
} from '@fortawesome/free-solid-svg-icons';
import { useCurrentProjectId, useProjects} from '@selectors/consumer/projects.selectors';
import classNames from 'classnames';
import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Input } from 'reactstrap';
import { SidebarCategoryLink, SidebarItemLink } from '@interfaces/panel/sidebar';
import { IStore } from '@store';
import { useUserAvatar, useUserBalance, useUserName, useUserRoles } from '@selectors/user.selectors';
import SidebarCategory from './sidebarCategory';
import SidebarItem from './sidebarItem';
import {panelActions} from "@store/panel/panelStore";
import {AddProjectModal} from "@components/consumer/projects/modals";
import {useHistory} from "react-router-dom";

const masterItems: SidebarCategoryLink[] = [
    {
        name: 'Хранилища',
        role: 'admin.master.storages.view',
        path: '/master/storage/',
        icon: faBoxes
    },
    {
        name: 'Прокси',
        role: 'admin.master.proxies.view',
        path: '/master/proxy/',
        icon: faNetworkWired
    }
];

const items: SidebarCategoryLink[] = [
    {
        name: 'Хранилища',
        role: 'admin.consumer.storages.view',
        path: '/storage/',
        icon: faBoxes
    },
    {
        name: 'Проект',
        role: 'admin.consumer.projects.view',
        path: '/p/:project_id/',
        icon: faSliders
    },
    {
        name: 'Сервера',
        icon: faServer,
        path: '/p/:project_id/s/',
        children: [
            {
                name: 'Авторизации',
                icon: faUsersLine,
                path: '/p/:project_id/s/l/'
            },
            {
                name: 'Игровые',
                icon: faGamepad,
                path: '/p/:project_id/s/g/'
            }
        ]
    },
    {
        name: 'Платежи',
        icon: faWallet,
        path: '/p/:project_id/ps/',
        project: true,
        children: [
            {
                name: 'Настройки',
                icon: faWrench,
                path: '/p/:project_id/ps/s/',
            },
            {
                name: 'Платежные шлюзы',
                icon: faCreditCard,
                path: '/p/:project_id/ps/gw/',
            },
            {
                name: 'Валюты',
                icon: faDollarSign,
                path: '/p/:project_id/ps/cr/',
            }
        ]
    },
    {
        name: 'Почта',
        icon: faEnvelope,
        path: '/p/:project_id/smtp/',
    },
    {
        name: 'Внешний вид',
        icon: faSliders,
        path: '/p/:project_id/v/',
        children: [
            {
                name: 'Темы',
                icon: faPalette,
                path: '/p/:project_id/v/t/',
            },
            {
                name: 'Языки',
                icon: faLanguage,
                path: '/p/:project_id/v/l/',
            }
        ]
    },
    {
        name: 'Модули',
        icon: faCubes,
        path: '/p/:project_id/pm/',
    },
    {
        name: 'API токены',
        icon: faCode,
        path: '/p/:project_id/api/',
    },
    {
        name: 'Файлы',
        icon: faBucket,
        path: '/p/:project_id/b/',
    }
]

const userItems: SidebarCategoryLink[] = [
    {
        name: 'Настройки',
        path: '/settings/',
        icon: faCog
    },
    {
        name: "Выход",
        path: '/signout/',
        icon: faDoorOpen
    }
]


const Sidebar: FC = () => {

    const dispatch = useDispatch();
    const {isOpened, isOnRight} = useSelector(({panel}: IStore) => panel.sidebar);
    const avatar = useUserAvatar();
    const balance = useUserBalance();
    const username = useUserName();
    const roles = useUserRoles();
    const history = useHistory();

    const {data: projects} = useProjects();
    const currentProject = useCurrentProjectId();
    const [modalOpen, setModalOpen] = React.useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);

    const onProjectSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const projectId = e.target.value;
        if (projectId === 'newProject') {
            toggleModal();
        } else {
            dispatch(panelActions.setVar({
                key: 'project',
                value: projectId
            }));

            history.push(`/p/${projectId}/`);
        }
    }

    return (
        <nav className={classNames(
            "sidebar",
            isOpened || "toggled",
            !isOnRight || "sidebar-right")}>
            <div className="sidebar-content">
                <a
                    className={classNames(
                        "sidebar-brand",
                        !isOnRight || "text-right"
                    )}
                    href="/"
                >
                    {/* {isOnRight || <IosPulse />}{" "} */}
                    <span className="align-middle">webpanel.su</span>{" "}
                    {/* {!isOnRight || <IosPulse />} */}
                </a>

                <div className="sidebar-user">
                    <img
                        src={avatar}
                        className="img-fluid rounded-circle mb-2"
                        alt={username}
                    />
                    <div className="font-weight-bold">{username}</div>
                    <small>Баланс: {balance}</small>
                </div>
                <ul className="sidebar-nav">
                    <Input type="select" className="ms-2 me-2 w-auto" value={currentProject} onChange={onProjectSelect}>
                        <option value=''>Выберите проект</option>
                        {
                            projects.projects.map(project => (
                                <option value={project.id}>{project.name}</option>
                            ))
                        }
                        <option value='newProject'>Создать проект</option>
                    </Input>
                    {
                        roles.includes('admin.master') && [
                            <li className="sidebar-header">Управление</li>,
                            <SidebarLinks items={masterItems} />
                        ]
                    }
                    {
                        roles.includes('admin.consumer') && [
                            <li className="sidebar-header">Настройки проекта</li>,
                            <SidebarLinks items={items} />
                        ]
                    }
                    <li className="sidebar-header">Пользователь</li>
                    <SidebarLinks items={userItems} />
                </ul>
            </div>
            <AddProjectModal
                isOpen={modalOpen}
                toggle={toggleModal} />
        </nav>
    )
}

interface SidebarLinksProps {
    items: SidebarCategoryLink[];
}

const SidebarLinks: FC<SidebarLinksProps> = ({items}) => {

    const currentProject = useCurrentProjectId();
    const roles = useUserRoles();

    return (
        <>
            {
                items.filter(x => (x.project && currentProject) || !x.project).map(category => {
                    if (category.role && !roles.includes(category.role)) {
                        return undefined;
                    }

                    return (
                        <React.Fragment key={category.path}>
                            {category.header ? (
                                <li className="sidebar-header">{category.header}</li>
                            ) : null}

                            {(category.children && category.children.length > 0) ? (
                                <SidebarCategory
                                    category={category}
                                >
                                    {category.children.map((route: SidebarItemLink, index) => (
                                        <SidebarItem
                                            item={route}
                                        />
                                    ))}
                                </SidebarCategory>
                            ) : (
                                <SidebarItem
                                    item={category}
                                />
                            )}
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}

export default Sidebar;