import { EditStorage, StoragesList } from "@components/consumer/storages";
import { MasterProxiesList } from "@components/master/proxies";
import { EditMasterStorage, MasterStoragesList } from "@components/master/storages";
import { EditProjectPage } from "@components/pages/consumer/projects";
import EmptyPage from "@components/pages/emptyPage";
import { ConfirmEmailPage, ForgotPage, ResetPasswordPage, SignInPage, SignUpPage } from "@components/pages/guest";
import { SettingsPage, SignOutPage } from "@components/pages/shared";
import { RouterItem } from "@interfaces/panel/routes";
import {
    AddCurrencyPage,
    CurrenciesPage, EditCurrencyPage,
    GatewaysPage,
    ProjectPaymentSettingsPage
} from "@components/pages/consumer/payments";
import {GameServersListPage, LoginServersListPage, EditGameServerPage, EditLoginServerPage} from "@components/pages/consumer/servers";
import {ApiTokensPage, BucketPage, SmtpSettingsPage} from "@components/pages/consumer";
import {PermissionsPage} from "@components/pages/consumer/permissions";
import {LanguagesListPage, EditLanguagePage} from "@components/pages/consumer/themes/languages";

export const guestRoutes: RouterItem = {
    path: '/',
    needAuth: false,
    children: [
        {
            path: '/signin/',
            component: SignInPage
        },
        {
            path: '/signup/',
            component: SignUpPage
        },
        {
            path: '/forgot/',
            component: ForgotPage
        },
        {
            path: '/reset/:code',
            exact: false,
            component: ResetPasswordPage
        },
        {
            path: '/confirm/:code',
            exact: false,
            component: ConfirmEmailPage
        }
    ]
}

export const sharedRoutes: RouterItem = {
    path: '/',
    needAuth: true,
    component: EmptyPage,
    children: [
        {
            path: '/settings/',
            name: 'Настройки',
            component: SettingsPage
        },
        {
            path: '/signout/',
            component: SignOutPage
        }
    ]
}

export const masterRoutes: RouterItem = {
    path: '/',
    needAuth: true,
    authRole: 'admin.master',
    children: [
        {
            path: '/master/storage/',
            exact: true,
            name: 'Хранилища',
            authRole: 'admin.master.storages.view',
            component: MasterStoragesList,
            children: [
                {
                    path: '/master/storage/:storage_id/',
                    name: 'Хранилище',
                    code: 'admin.master.storage.name',
                    component: EditMasterStorage
                }
            ]
        },
        {
            path: '/master/proxy/',
            exact: true,
            name: 'Прокси',
            authRole: 'admin.master.proxies.view',
            component: MasterProxiesList
        }
    ]
}

export const consumerRoutes: RouterItem = {
    path: '/',
    needAuth: true,
    authRole: 'admin.consumer',
    children: [
        {
            path: '/storage/',
            exact: true,
            name: 'Хранилища',
            authRole: 'admin.consumer.storages.view',
            component: StoragesList,
            children: [
                {
                    path: '/storage/:storage_id/',
                    name: 'Хранилище',
                    code: 'admin.storage.name',
                    component: EditStorage
                }
            ]
        },
        {
            path: '/p/:project_id/',
            code: 'admin.consumer.project.name',
            exact: true,
            component: EditProjectPage,
            children: [
                {
                    path: '/p/:project_id/s/l/',
                    name: 'Сервера авторизации',
                    component: LoginServersListPage,
                    children: [
                        {
                            path: '/p/:project_id/s/l/:server_id/',
                            name: 'Сервер авторизации',
                            code: 'admin.consumer.project.login.name',
                            component: EditLoginServerPage
                        }
                    ]
                },
                {
                    path: '/p/:project_id/s/g/',
                    name: 'Игровые сервера',
                    component: GameServersListPage,
                    children: [
                        {
                            path: '/p/:project_id/s/g/:server_id/',
                            name: 'Игровой сервер',
                            code: 'admin.consumer.project.game.name',
                            component: EditGameServerPage
                        }
                    ]
                },
                {
                    path: '/p/:project_id/ps/',
                    name: 'Платежи',
                    children: [
                        {
                            path: '/p/:project_id/ps/s/',
                            name: 'Настройки',
                            exact: true,
                            component: ProjectPaymentSettingsPage
                        },
                        {
                            path: '/p/:project_id/ps/gw/:gateway?/:settings_id?',
                            name: 'Платежные шлюзы',
                            exact: false,
                            component: GatewaysPage
                        },
                        {
                            path: '/p/:project_id/ps/cr/',
                            name: 'Валюты',
                            exact: true,
                            component: CurrenciesPage,
                            children: [
                                {
                                    path: '/p/:project_id/ps/cr/add',
                                    name: 'Добавление валюты',
                                    exact: true,
                                    component: AddCurrencyPage
                                },
                                {
                                    path: '/p/:project_id/ps/cr/:currency_id/',
                                    name: 'Редактирование валюты',
                                    exact: true,
                                    component: EditCurrencyPage
                                }
                            ]
                        }
                    ]
                },
                {
                    path: '/p/:project_id/smtp/',
                    name: 'Почта',
                    exact: true,
                    component: SmtpSettingsPage
                },
                {
                    path: '/p/:project_id/api/',
                    name: 'API токены',
                    exact: true,
                    component: ApiTokensPage
                },
                {
                    path: '/p/:project_id/b/',
                    name: 'Файлы',
                    exact: true,
                    component: BucketPage
                },
                {
                    path: '/p/:project_id/pm/',
                    name: 'Модули',
                    exact: true,
                    component: PermissionsPage
                },
                {
                    path: '/p/:project_id/v/',
                    name: 'Внешний вид',
                    children: [
                        {
                            name: 'Темы',
                            path: '/p/:project_id/v/t/'
                        },
                        {
                            name: 'Языки',
                            path: '/p/:project_id/v/l/',
                            component: LanguagesListPage,
                            children: [
                                {
                                    name: 'Язык',
                                    path: '/p/:project_id/v/l/:language_id/',
                                    component: EditLanguagePage
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}