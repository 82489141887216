import React, { useEffect, useState, useRef } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, Button, Spinner, Table, Pagination, PaginationItem, PaginationLink, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import ThemesService from '@api/consumer/themesService';
import { ILanguage } from '@interfaces/consumer/themes';
import { toastr } from 'react-redux-toastr';
import { PutLanguageModel, PatchLanguageModel } from '@models/consumer/themes';
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import { withPage } from '@components/panel';

// Define interface for route params. Assuming projectId is also required by the service.
interface EditLanguagePageParams {
    language_id: string;
}

const ITEMS_PER_PAGE = 15;

interface EditLanguageFormValues {
    name: string;
    isActive?: boolean;
    code: string;
    strings: Array<{ key: string; value: string }>;
}

const EditLanguagePage: React.FC = () => {

    const projectId = useCurrentProjectId();
    const { language_id } = useParams<EditLanguagePageParams>();
    const [language, setLanguage] = useState<ILanguage | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const isNew = language_id === "new";
    
    useEffect(() => {
        if (isNew) {
            setLanguage({ name: "", isActive: true, strings: {} } as ILanguage);
            setLoading(false);
            return;
        }
        setLoading(true);
        const service = new ThemesService();
        service.getLanguage(projectId, language_id)
            .then(response => {
                if(response.success) {
                    setLanguage(response.data);
                }
            })
            .finally(() => setLoading(false));
    }, [isNew, language_id, projectId]);

    if (loading || !language) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Редактирование языка</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </CardBody>
            </Card>
        );
    }

    // Изменение initialValues для нового языка без чекбокса isActive
    const initialValues: EditLanguageFormValues = isNew ? {
        name: "",
        code: "",
        strings: []
    } : {
        name: language!.name,
        code: language!.code,
        isActive: language!.isActive,
        strings: Object.entries(language!.strings || {}).map(([key, value]) => ({ key, value }))
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
    });

    const handleSubmit = async (values: typeof initialValues) => {
        const service = new ThemesService();
        const updatedStrings: { [key: string]: string } = {};
        values.strings.forEach(item => {
            updatedStrings[item.key] = item.value;
        });
        if (isNew) {
            const newLanguage: PutLanguageModel = {
                parentId: undefined,
                projectId,
                name: values.name,
                code: values.code,
                strings: updatedStrings
            };
            try {
                const response = await service.putLanguage(newLanguage);
                if (response.success) {
                    toastr.success('Создание языка', 'Язык успешно создан');
                    history.replace(`/p/${projectId}/v/l/${response.data.id}/`);
                } else {
                    if (response.errorCode === 'ProjectNotFound') {
                        toastr.error('Создание языка', 'Проект не найден');
                    }
                    else if(response.errorCode === 'LanguageAlreadyExists') {
                        toastr.error('Создание языка', 'Язык с таким кодом уже существует');
                    }
                    else {
                        toastr.error('Создание языка', 'Ошибка при создании языка');
                    }
                }
            } catch {
                toastr.error('Создание языка', 'Ошибка при создании языка');
            }
        } else {
            const updatedLanguage: PatchLanguageModel = {
                id: language_id,
                projectId,
                name: values.name,
                isActive: !!values.isActive,
                strings: updatedStrings,
                code: language!.code
            };
            try {
                const response = await service.patchLanguage(updatedLanguage);
                if (response.success) {
                    toastr.success('Редактирование языка', 'Язык успешно обновлен');
                } else {
                    if (response.errorCode === 'ProjectNotFound') {
                        toastr.error('Редактирование языка', 'Проект не найден');
                    } else if (response.errorCode === 'LanguageNotFound') {
                        toastr.error('Редактирование языка', 'Язык не найден');
                    } else {
                        toastr.error('Редактирование языка', 'Ошибка при обновлении языка');
                    }
                }
            } catch {
                toastr.error('Редактирование языка', 'Ошибка при обновлении языка');
            }
        }
    };

    const handleExport = (strings: Array<{ key: string; value: string }>) => {
        const dataObj: Record<string, string> = {};
        strings.forEach(item => {
            if(item.key) {
                dataObj[item.key] = item.value;
            }
        });
        const data = JSON.stringify(dataObj, null, 2);
        const blob = new Blob([data], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `language_${language_id}_strings.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>{isNew ? "Создание языка" : "Редактирование языка"}</CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting }) => {
                        const totalItems = values.strings.length;
                        const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
                        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
                        const paginatedItems = values.strings.slice(startIndex, startIndex + ITEMS_PER_PAGE);

                        // Render pagination separately for clarity.
                        const renderPagination = () =>
                            totalPages > 1 && (
                                <Pagination>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <PaginationItem active={currentPage === index + 1} key={index}>
                                            <PaginationLink
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentPage(index + 1);
                                                }}>
                                                {index + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                </Pagination>
                            );

                        const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                try {
                                    const text = await file.text();
                                    const importedData = JSON.parse(text);
                                    if (importedData && typeof importedData === 'object' && !Array.isArray(importedData)) {
                                        const importedStrings = Object.entries(importedData).map(
                                            ([key, value]) => ({ key, value })
                                        );
                                        setFieldValue('strings', importedStrings);
                                        toastr.success(isNew ? 'Создание языка' : 'Редактирование языка', 'Строки успешно импортированы');
                                    } else {
                                        toastr.error(isNew ? 'Создание языка' : 'Редактирование языка', 'Неверный формат файла');
                                    }
                                } catch (error) {
                                    toastr.error(isNew ? 'Создание языка' : 'Редактирование языка', 'Ошибка при чтении файла');
                                }
                            }
                            if (fileInputRef.current) fileInputRef.current.value = "";
                        };

                        return (
                            <Form>
                                {/* Скрытый input для импорта */}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept="application/json"
                                    onChange={onFileChange}
                                />
                                <FormGroup>
                                    <Label for="name">Название</Label>
                                    <Field id="name" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="code">Код языка</Label>
                                    <Field id="code" name="code" className="form-control" disabled={!isNew}/>
                                    <ErrorMessage name="code" component="div" className="text-danger" />
                                </FormGroup>
                                {/* Показываем чекбокс только для существующего языка */}
                                {language_id !== "new" && (
                                    <FormGroup check>
                                        <Label check>
                                            <Field type="checkbox" name="isActive" className="form-check-input" />{' '}
                                            Активен
                                        </Label>
                                    </FormGroup>
                                )}

                                <h5 className="mt-4">Строковые данные (ключ-значение)</h5>
                                <FieldArray name="strings">
                                    {arrayHelpers => (
                                        <>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Button 
                                                    color="info" 
                                                    className="me-2" 
                                                    onClick={() => handleExport(values.strings)}
                                                >
                                                    Экспорт строк
                                                </Button>
                                                <Button 
                                                    color="info" 
                                                    className="me-2" 
                                                    onClick={() => fileInputRef.current?.click()}
                                                >
                                                    Импорт строк
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    type="button"
                                                    onClick={() => {
                                                        arrayHelpers.push({ key: '', value: '' });
                                                        const newTotalItems = values.strings.length + 1;
                                                        setCurrentPage(Math.ceil(newTotalItems / ITEMS_PER_PAGE));
                                                    }}
                                                >
                                                    Добавить строку
                                                </Button>
                                            </div>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Ключ</th>
                                                        <th>Значение</th>
                                                        <th>Действия</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paginatedItems.map((item, index) => {
                                                        const realIndex = startIndex + index;
                                                        return (
                                                            <tr key={realIndex}>
                                                                <td>
                                                                    <Field
                                                                        name={`strings.${realIndex}.key`}
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                        name={`strings.${realIndex}.value`}
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        color="danger"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(realIndex);
                                                                        }}
                                                                    >
                                                                        Удалить
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                            {renderPagination()}
                                        </>
                                    )}
                                </FieldArray>
                                <div className="d-flex justify-content-end mt-3">
                                    <Button color="primary" type="submit" disabled={isSubmitting}>
                                        {language_id === "new" ? "Создать" : "Сохранить"}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default withPage(EditLanguagePage);
