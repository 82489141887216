import {FC} from "react";
import {LanguagesListTable} from "@components/consumer/themes/languages";
import {withPage} from "@components/panel";

const LanguagesListPage: FC = () => {
    return (
        <LanguagesListTable />
    )
}

export default withPage(LanguagesListPage);