import ServiceBase from "@api/serviceBase";
import {PatchLanguageModel, PutLanguageModel} from "@models/consumer/themes";
import {ILanguage, ILanguageShort} from "@interfaces/consumer/themes";

export default class ThemesService extends ServiceBase {

    getLanguages(projectId: string) {
        return this.getApi<ILanguageShort[]>(`/admin/language/${projectId}/`);
    }

    getLanguage(projectId: string, languageId: string) {
        return this.getApi<ILanguage>(`/admin/language/${projectId}/${languageId}/`);
    }

    putLanguage(language: PutLanguageModel) {
        return this.putApi<ILanguage>(`/admin/language/`, language);
    }

    patchLanguage(language: PatchLanguageModel) {
        return this.patchApi<ILanguage>(`/admin/language/`, language);
    }

    deleteLanguage(projectId: string, languageId: string) {
        return this.deleteApi(`/admin/language/${projectId}/${languageId}/`);
    }
}